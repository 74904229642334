<template>
    <div>
        <TitleBar>知识货架</TitleBar>
        <div class="main-container">
            <div class="data-panel">
                <div class="actions">
                    <Button type="primary" icon="md-add-circle" @click="$router.push({ name: 'knowledge-shelf-add' })">新建知识货架</Button>
                </div>
                <Table :columns="columns" :data="dataList" class="data-table">
                    <template slot="coverImg" slot-scope="{ row }">
                        <img :src="row.coverImg" />
                    </template>
                    <template slot="bindTemplate" slot-scope="{ row }">
                        <span class="bind-status" :class="{ enable: row.bindCardEnabled }">{{
                            row.bindCardEnabled ? "已绑定" : "未绑定"
                        }}</span>
                    </template>
                    <template slot="action" slot-scope="{ row }">
                        <button @click="editArticle(row.id)">
                            <i class="nazaio-iconfont nz-edit-square" />
                        </button>
                        <button>
                            <Poptip
                                placement="bottom-end"
                                width="120"
                                offset="12"
                                :transfer="true"
                                v-model="row.pop"
                                transfer-class-name="ks-poper"
                            >
                                <i class="nazaio-iconfont nz-more-horiz" />
                                <div slot="content" class="sub-btns">
                                    <div class="sub-btn">
                                        <button @click="remove(row)"><i class="nazaio-iconfont nz-delete" />删除</button>
                                    </div>
                                    <div class="sub-btn">
                                        <button @click="report(row.id)"><i class="nazaio-iconfont nz-dashboard-bar" />报表</button>
                                    </div>
                                    <div class="sub-btn">
                                        <button @click="qrcode(row)"><i class="nazaio-iconfont nz-qrcode-ordinary" />二维码</button>
                                    </div>
                                </div>
                            </Poptip>
                        </button>
                    </template>
                </Table>
                <div class="pagination" v-if="totalSize / query.pageSize > 1">
                    <Page @on-change="pageChange" :total="totalSize" :pageSize="query.pageSize" show-elevator show-total />
                </div>
            </div>
        </div>
        <Modal v-model="showQrcodeDialog" width="400" title="查看二维码">
            <div id="qrcode" class="qrcode-img" ref="qrcode">
                <img :src="qrcodeSrc" />
            </div>
            <div slot="footer">
                <Button @click="showQrcodeDialog = false">关闭</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import { getList, remove, getQrcode } from "@/api/knowledge-shelf";

export default {
    components: {
        TitleBar,
    },
    created() {
        this.getList();
    },
    data() {
        return {
            columns: [
                {
                    title: "封面图",
                    slot: "coverImg",
                    align: "center",
                    width: 200,
                },
                {
                    title: "知识货架名称",
                    key: "name",
                },
                {
                    title: "绑定名片模板",
                    slot: "bindTemplate",
                    // width: 180,
                    align: "center",
                },
                {
                    title: "创建时间",
                    key: "createTime",
                    align: "center",
                    width: 200,
                },
                {
                    title: "更新时间",
                    key: "updateTime",
                    align: "center",
                    width: 200,
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    width: 100,
                },
            ],
            totalSize: 0,
            dataList: [],
            query: {
                pageNum: 1,
                pageSize: 20,
            },
            showQrcodeDialog: false,
            qrcodeSrc: "",
        };
    },
    methods: {
        getList() {
            getList(this.query).then((response) => {
                this.totalSize = response.totalSize;
                this.dataList = response.data;
                this.dataList.forEach((d) => this.$set(d, "pop", false));
            });
        },
        pageChange(pageNum) {
            this.query.pageNum = pageNum;
            this.getList();
        },
        editArticle(id) {
            this.$router.push({ path: `/social-card/knowledge-shelf/edit/${id}` });
        },
        report(id) {
            this.$router.push({ path: `/social-card/knowledge-shelf/report/${id}` });
        },
        remove(row) {
            row.pop = false;
            this.$Modal.confirm({
                title: "确定删除该知识货架吗？",
                content: "<p>删除知识货架后，应用该知识货架的名片模板中不再显示该知识货架，是否确认删除该知识货架？</p>",
                onOk: () => {
                    remove(row.id).then(() => {
                        this.$Message.success("知识货架已成功删除！");
                        this.getList();
                    });
                },
            });
        },
        qrcode(row) {
            row.pop = false;
            this.showQrcodeDialog = true;
            getQrcode(row.id).then((res) => {
                let blob = new Blob([res.data], { type: "image/png" });
                let url = window.URL.createObjectURL(blob);
                this.qrcodeSrc = url;
            });
        },
        arrayBufferToBase64(buffer) {
            let binary = "";
            let bytes = new Uint8Array(buffer);
            let len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },
    },
};
</script>

<style lang="less" scoped>
.actions {
    margin-bottom: 16px;
}
.data-panel {
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;

    
}

.data-table {
    img {
        width: 120px;
        height: 80px;
        object-fit: cover;
        display: block;
        margin: 10px auto;
    }

    .bind-status {
        color: #c6c9cf;

        &:before {
            margin-right: 12px;
            content: "●";
            color: #c6c9cf;
            font-size: 20px;
        }

        &.enable {
            color: #395069;

            &:before {
                color: #19be6b;
            }
        }
    }

    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        width: 20px;
        line-height: 20px;
        height: 20px;
        color: #395069;
        margin: 0 5px;

        &:hover {
            color: @fc-main-color;
        }
    }
}

.sub-btn button {
    padding: 7px 20px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #395069;

    &:hover {
        color: @fc-main-color;
    }

    i {
        margin-right: 14px;
    }
}

.pagination {
    margin-top: 24px;
    text-align: right;
}

.qrcode-img {
    text-align: center;
    margin: 16px auto 16px auto;
    width: 124px;
    height: 124px;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
</style>
<style lang="less">
.ks-poper {
    min-width: 90px;

    .ivu-poptip-body {
        padding: 0;
    }

    .ivu-poptip-body-content {
        overflow: hidden;
    }
}
</style>